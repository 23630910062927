import React from 'react'
import { Link, graphql } from 'gatsby'
import PageTitle from 'components/page-title'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import Layout from 'components/layout'
import Hero from 'components/hero'
import ImageOverlay from 'components/image-overlay'
import ArticleTile from 'components/article-tile'

const IndexPage = ({ data }) => (
  <Layout>
    <PageTitle title="Home" />
  
    <Hero article={data.articles.edges[0].node} />
    
    <section className="l-highlights">
      <div>
        <h2><Link to="/blog">Recent Blog Posts</Link></h2>
        <Link to={'/blog'}>Read the blog <FontAwesomeIcon icon={faArrowRight} /></Link>
      </div>
      {data.articles.edges.slice(1, 4).map(article => (
        <ArticleTile article={article.node} key={article.node.fields.slug} />
      ))}
    </section>

    <section className="l-columnar">
      <ImageOverlay sizes={data.family.childImageSharp.fluid} link="/fundamentals/family-farming" heading="Our Farm Family" description="&quot;&hellip;walk worthy of the vocation wherewith you are called.&quot; &ndash; Ephesians 4:1" />
      <ImageOverlay sizes={data.farm.childImageSharp.fluid} link="/fundamentals/farm-history" heading="Farm History" description="<em>Growing With Gratitude</em> for more than 40 years." />
    </section>

    <section className="l-columnar">
      <ImageOverlay sizes={data.sustain.childImageSharp.fluid} link="/fundamentals/sustainability" heading="Sustainability" description="Our land is our greatest asset. Learn how we sustain it for generations to come." />
      <ImageOverlay sizes={data.careers.childImageSharp.fluid} link="/careers" heading="Careers" description="We're hiring! Join our growing, progressive operation." />
    </section>

    <section className="l-advert">
      <div>
        <h2>Opportunities for Landowners</h2>
        <p>Whether your land is purely an investment, a part of your family heritage, a piece of what once was or still is your farming operation, or even a little bit of each, Howell Farms understands the value of your land and would like to offer its <Link to={'/fundamentals/sustainability'}>stewardship</Link> to earn you a competitive return on your investment.</p>
      </div>          
      <Link to={'/landowners'} className="buttonLink">Learn why our neighbors entrust us with their land</Link>
    </section>
    
    <section className="l-columnar">
      <ImageOverlay sizes={data.tomatoes.childImageSharp.fluid} link="/fundamentals/what-we-grow/tomatoes" heading="Tomatoes" />
      <ImageOverlay sizes={data.grains.childImageSharp.fluid} link="/fundamentals/what-we-grow/grains" heading="Grains" />
    </section>
    
  </Layout>
)

export const query = graphql`
  query {
    articles: allMarkdownRemark(
      filter: { 
        fields: { 
          type: { eq: "articles"}
        }
      }, 
      sort: {fields: [frontmatter___date], order: DESC},
      limit: 4
    ) {
      edges {
        node {
          fields {
            type
            slug
          }
          frontmatter {
            title
            date(formatString: "MMM DD, YYYY")
            author
            category
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            
          }
        }
      }
    }
    family: file(relativePath: { eq: "howell1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pumpkins: file(relativePath: { eq: "pumpkins.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tomatoes: file(relativePath: { eq: "tomatoes.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    grains: file(relativePath: { eq: "grains.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tours: file(relativePath: { eq: "tours.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tractor: file(relativePath: { eq: "tractor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    article: file(relativePath: { eq: "article.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sustain: file(relativePath: { eq: "sustain.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    careers: file(relativePath: { eq: "careers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    farm: file(relativePath: { eq: "farm.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    toursHero: file(relativePath: { eq: "tour-wagon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage