import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import ArticleMeta from 'components/article-meta'

import './hero.scss'

const Hero = ({ article }) => (
  <div className="c-hero">
    <Img sizes={article.frontmatter.image.childImageSharp.fluid} />
    <div className="container c-hero__overlay">
      <ArticleMeta article={article} />
      <Link to={article.fields.slug} className="buttonLink">Read more</Link>
    </div>
  </div>
)

export default Hero
